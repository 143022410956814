/* General Styles */
.bgfooter {
    background-color: #2B2038;
    padding: 50px 10px;
}

.textfooter {
    line-height: 1.6;
    font-size: 18px;
    color: white;
}

/* Icon styles */
.iconcircle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px; 
    height: 30px; 
    background-color: white;
    border-radius: 50%;
    font-size: 20px; 
    text-align: center;
    margin-bottom: 16px;
    margin-left: 10px;
    padding: 18px 18px;
}

/* Line Divider */
.footer-line {
    border: none;
    border-top: 2px solid white; 
    margin: 20px 0; 
    width: 100%; 
}

/* Media Queries for Responsiveness */
@media (max-width: 767px) {
    .logo img {
        width: 100px;
        height: 100px;
    }
    
    .textfooter {
        font-size: 16px;
    }

    .iconcircle {
        width: 25px;
        height: 25px;
        font-size: 18px;
    }

    .footer-line {
        margin: 15px 0;
    }

    .container {
        padding: 20px;
    }
}

@media (max-width: 575px) {
    .col-md-5, .col-md-7 {
        text-align: center;
    }
    
    .ul {
        padding-left: 0;
        text-align: center;
    }

    .ul li {
        margin-bottom: 10px;
    }

    .iconcircle {
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .container.d-flex {
        flex-direction: column;
        align-items: center;
    }
}
