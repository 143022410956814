.content {
    background-color: #FBEBD7;
    min-height: 100vh;
    padding: 10px 20px;
}

.pointer {
    cursor: pointer;
}

.btn1, .btn2 {
    border-radius: 8px;
    font-size: 16px; 
}

.btn1 {
    border: solid 2px #AC6CFF;
    background-color: transparent; 
    color: #AC6CFF; 
    transition: all 0.3s ease; /* Smooth transition effect */

}

.btn1:hover
{
    background-color: #AC6CFF !important;
    color: #FFFF;
    border: 0;
    transform: scale(1.05); /* Slightly scale up the button on hover */

}

.explainborder {
    border-top: 2px solid #AC6CFF;
    padding-top: 10px; 
}

.explain {
    border-bottom: 2px solid #AC6CFF;
    width: 10%; 
    margin: auto;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .content {
        padding: 10px 15px;
    }

    .btn1 {
        font-size: 14px;
    }

    .explain {
        width: 10%;
    }
}

@media (max-width: 992px) {
    .content {
        padding: 10px 10px;
    }

    .btn1 {
        font-size: 12px;
        padding: 10px;
    }

    .explain {
        width: 15%;
    }
}

@media (max-width: 768px) {
    .content {
        padding: 5px 5px;
    }

    .btn1{
        font-size: 12px;
        padding: 8px;
    }

    .explain {
        width: 10%;
    }
}

@media (max-width: 576px) {
    .btn1, .btn2 {
        font-size: 11px;
        padding: 6px;
    }

    .explain {
        width: 40%;
    }
}
