.coursesSection {
  background-color: #FBEBD7;
  min-height: 100vh;
  padding-top: 70px;
}

.card {
  border: none;
  background-color: #EADAFF !important;
  transition: all 0.3s ease-in-out;
}
.card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  background-color: #EADAFF !important;
  transition: all 0.3s ease-in-out;
}
.pointer
{
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.card-body {
  padding: 20px;
}

.card-text {
  font-size: 14px;
}

.colorthird {
  color: #AC6CFF;
}

@media (max-width: 768px) {
  .card-body {
    padding: 10px;
  }

  .card-text {
    font-size: 12px;
  }

  .card-img-top {
    height: 150px;
  }
}

@media (max-width: 576px) {
  .card-body {
    padding: 8px;
  }

  .card-text {
    font-size: 11px;
  }

  .card-img-top {
    height: 120px;
  }
}

 