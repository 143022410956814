.home {
    background-image: url(../../Assets/images/Hero\ Page.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
    min-height: calc(100vh + 80px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.bgsec {
    background-color: #AC6CFF !important;
    padding: 15px 20px;
    border-radius: 10px;
    font-size:18px !important;
    margin-top: 20px;
}

.textmain {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.colorthird {
    color: #AC6CFF;
}

@media (max-width: 768px) {
    .home {
        height: auto;
        padding: 40px 0;
    }

    .textmain {
        font-size: 1.2rem;
        text-align: center;
    }

    h2 {
        font-size: 1.8rem;
    }

    .col-md-8, .col-md-4 {
        text-align: center;
    }

    img {
        width: 80%;
        margin-top: 20px;
        object-fit: cover;
    }
}

@media (max-width: 576px) {
    .home {
        padding: 20px 0;
    }

    .textmain {
        font-size: 1rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    img {
        width: 70%;
    }
}
