.login {
    background-image: url(../../Assets/images/Login.png);
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
  }
  
  .container {
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
    
  }
  
  .input {
    width: 100%;
    padding: 8px 10px;
    margin-bottom: 15px;
    border: 1px solid #AC6CFF;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
    text-align: start !important;
  }
  
  .button {
    width: 100%;
    padding: 8px 10px;
    background-color: #B77FFF;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #9f61ff;
  }
  
  .input::placeholder {
    color: #999;
    font-size: 14px;
  }
  
  .input:focus {
    border: 2px solid #B77FFF;
    outline: none;
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }
  
  .title::before,.title::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 0px;
    background-color: #B77FFF ;
  }
  
  .title::before {
    width: 18px;
    height: 18px;
    background-color: #B77FFF ;
  }
  
  .title::after {
    width: 18px;
    height: 18px;
    animation: pulse 1s linear infinite;
  }
  
  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }
  
    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }







  
  @media (min-width: 576px) {
    .container {
      max-width: 500px;
    }
  
    .title {
      font-size: 26px;
    }
  
    .input {
      font-size: 18px;
    }
  
    .button {
      font-size: 18px;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 600px;
    }
  
    .title {
      font-size: 28px;
    }
  
    .input {
      font-size: 20px;
    }
  
    .button {
      font-size: 20px;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 400px;
    }
  
    .title {
      font-size: 30px;
    }
  
    .input {
      font-size: 22px;
    }
  
    .button {
      font-size: 16px;
    }
  }
  