.stages {
    background-image: url(../../Assets/images/NotFound-1.png);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    /* background-color: #FBEBD7; */
    min-height: 100vh;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.one {
    background-color: #AC6CFF;
    padding: 40px 20px;
    border-radius: 16px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.one:hover {
    transform: translateY(-5px);
}

.img {
    width: 50px;
}

.textmain {
    color: #ffffff;
    font-size: 1.25rem;
}

@media (min-width: 576px) {
    .textmain {
        font-size: 1.5rem;
    }

    .img {
        width: 60px;
    }
}

@media (min-width: 768px) {
    .textmain {
        font-size: 1.75rem;
    }

    .img {
        width: 70px;
    }
}

@media (min-width: 992px) {
    .textmain {
        font-size: 2rem;
    }

    .img {
        width: 80px;
    }
}
