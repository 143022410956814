@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95); /* Slight zoom effect */
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content {
  animation: fadeIn 0.5s ease; /* Apply the fade-in animation */
  max-width: 90%; /* Make it responsive */
  margin: auto; /* Center horizontally */
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center vertically */
  margin: 0; /* Remove default margin */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: background overlay */
}

/* Responsiveness */
@media (min-width: 576px) {
  .modal-content {
    max-width: 500px; /* Larger size for medium and above screens */
  }
}

@media (min-width: 768px) {
  .modal-content {
    max-width: 600px; /* Larger size for bigger screens */
  }
}

@media (min-width: 992px) {
  .modal-content {
    max-width: 700px;
  }
}

.bgsec {
  background-color: #AC6CFF; /* Your defined color */
}

.sec {
  color: #F8F7FC; /* Your defined text color */
}
