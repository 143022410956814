body {
  margin: 0;
  font-family: 'Almarai', sans-serif;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}


.textmain {
  color: #F8F9FF;
}

.bgnav {
  background-color: #330c56;
}

.colorthird {
  color: #AC6CFF;
}


.active {

  transition: 0.8s;
  border-bottom: 2px solid #AC6CFF;
}

.bgnav {
  /* background-color: ; */
}

.about-us-h1::after{
  content: "";
  width: 130px;
  height: 3px;
  background-color: #AC6CFF;
  display: block;
  margin-top: 10px;
}
.stages-h1::after{
  content: "";
  width: 270px;
  height: 3px;
  background-color: #AC6CFF;
  display: block;
  margin-top: 10px;
}

::-webkit-scrollbar {
  width: 9px;
  border-radius: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#AC6CFF ;
}

::selection {
  color: #AC6CFF;
}





