.sub {
    background-color: #FBEBD7;
    min-height: 100vh;
    padding-top: 70px;
  }
  
  .bg {
    background-color: #EADAFF;
    border-radius: 16px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .bg:hover {
    transform: translateY(-5px);
  }
  
  .colorthird {
    color: #333; 
    font-size: 1.25rem;
  }
  .bgsec {
    background-color: #AC6CFF;
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  @media (min-width: 576px) {
    .colorthird {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 768px) {
    .colorthird {
      font-size: 1.75rem;
    }
  }
  
  @media (min-width: 992px) {
    .colorthird {
      font-size: 2rem;
    }
  }
  