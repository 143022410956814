.AboutUs {
    background-image: url(../../Assets/images/NotFound-1.png);
    background-size: cover;
    background-position: center;
    min-height: calc(100vh + 80px);
    width: 100%;
    background-repeat: no-repeat; 
}

.title h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #AC6CFF !important;
}

.video video {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.bgsec {
    background-color: #AC6CFF;
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-top: 20px;
}
.card {
    background-color: #f8f9fa; /* Light grey background */
    border-radius: 10px;
    border: none;
    width: 80%;
  }
  
  .textcard {
    color: #333 !important; /* Customize the text color */
  }
  
  .colorthird {
    color: #ff6f61; /* Customize the heading color */
  }
  
@media (max-width: 768px) {
    .AboutUs {
        padding: 30px 0;
    }

    .title h3{
        font-size: 1.5rem;
       
    }
    .video video {
        width: 100%;
        height: auto;
    }

    .bgsec {
        font-size: 1rem;
        padding: 10px 15px;
    }
}

@media (max-width: 576px) {
    .AboutUs {
        padding: 20px 0;
    }

    .title h3 {
        font-size: 1.2rem;
    }

    .bgsec {
        font-size: 0.9rem;
        padding: 8px 10px;
    }
}
